jQuery(function($) {
    const $body = $('body');
    const menuOffset = function(){ return ($('nav.main').outerHeight() + ($('nav.main .nag:visible').height() || 0)) * -1};

    //region - report widgets and effects
    const $groupBy = $('footer .preview');
    const $reportForm = $('.report main > form');
    const $container = $('.countries.selected > div');
    const $questionBar = $('header nav > div.selected > span');
    const $countries = $('.countries input:checkbox.country');
    const $countriesLang = $('.countries input:checkbox.language');
    const $questions = $('.question input:checkbox');

    const $checkboxesGroup = $('label div.language');

    if ('_currentReport' in window) {
        console.log(window._currentReport.countryLanguage);

        $countries.each(function () {
            this.checked = window._currentReport.countries.indexOf(this.value) > -1;
        });

        $checkboxesGroup.each(function () {
            const languageList = window._currentReport.countryLanguage;

            for (let i = 0; i < languageList.length; i++) {
              let labelId;

              if ($(this).hasClass(languageList[i])) {
                labelId = $(this).closest('label').data('id');
                $(this).find('input').prop('checked', true);
                $(this).closest('label.multilingual').addClass('checked');
                $(`label[data-id="${labelId}"]`).find(`.selection > button[data-locale="${$(this).data('lang')}"]`).addClass('checked');
              }
            }
        });

        $questions.each(function () {
            this.checked = window._currentReport.questions.indexOf(this.value) > -1;
            const $question = $(this).closest('.question');
            $question.toggleClass('selected', this.checked);
            if (this.checked && $questionBar.children().length < 10 ) {
                $questionBar.append($('<button>', {'class': 'link', html: $question.data('code')}));
            }
        });

        $questionBar.parent().css('opacity', $questionBar.children().length);
    }

    //region -- Select all toggle
    const selectAll = $('[data-action="select-all"]');
    const selectAllCountries = $('[data-action="select-all-countries"]');

    let isAllCountriesSelected = false;

    selectAllCountries.on('click', function (e) {
        e.preventDefault();

        if (!isAllCountriesSelected) {
            isAllCountriesSelected = true;
            selectAll.filter('.inverted').trigger('click');
            selectAll.trigger('click');
        } else {
            isAllCountriesSelected = false;
            selectAll.filter('.inverted').trigger('click');
        }

        $(this)
            .html(isAllCountriesSelected ? 'Deselect All Countries' : 'Select All Countries')
            .toggleClass('inverted', isAllCountriesSelected);
    });

    selectAll
        //init select all button states
        .each(function(){
            const reverse = $(this).parents('section').find('input:enabled:not(:checked)').length === 0;
            $(this).html(reverse ? 'Deselect All' : 'Select All').toggleClass('inverted', reverse)
        })
        //select/de-select all operation
        .on('click', function (e) {
            const $this = $(this);
            const filter = $this
                .closest('section')
                .find( `${$this.data('target')}` )
                .filter(':enabled:not(:checked)').length === 0 ? ':checked' : ':not(:checked)';

            $this.closest('section').find(`${$this.data('target')}${filter}`).trigger('click');

            if (selectAllCountries.hasClass('inverted')) {
                isAllCountriesSelected = false;
                selectAllCountries.removeClass('inverted').html('Select All Countries');
            }
        })
        //post-op update state
        .on('click', function (e) {
            e.preventDefault();
            const reverse = $(this).parents('section').find('input:enabled:not(:checked)').length === 0;
            $(this).html(reverse ? 'Deselect All' : 'Select All').toggleClass('inverted', reverse)
        });
    //endregion

    //Question selection and form submit binding
    $('.question').on('click', function (e) {
        const $this = $(this), checked = !this.classList.contains('selected');
        $this.toggleClass('selected').find('input:checkbox').prop('checked', checked).trigger('change');
    }).find('input:checkbox').on('change', $.debounce(500, false, function () {
        $questionBar.html('loading...');

        $.post('', $reportForm.serialize(), () => {
            $questionBar.html('');

            $reportForm.find('.compact .question input:not(:checked)').closest('.question').fadeOut(300, function() {
                $(this).remove();
            });

            $reportForm.find('.question input:checked').each(function () {
                const $this = $(this);
                if ($questionBar.children().length < 10) {
                    $questionBar.append($('<button>', {'class': 'link', html: $this.data('code')}));
                }
                $questionBar.parent().css('opacity', 1);
            });
        });
    }));

    //Reset button
    $('header > nav.report > a[data-rel="reset"]').on('click', function (e) {
        e.preventDefault();
        if ( confirm('Are you sure?') ){
            $reportForm.find('[name="action"]').val('torinoProcess/report/resetReport');
            $reportForm.submit();
        }
    });

    //force preview in new tab
    $('input[name="preview"]').on('click', function (e) {
        $reportForm.attr('target', '_blank');
    });

    $('*[name="generate"]').on('click', function (e) {
        if ($(this).hasClass('disabled')) {
            return false;
        }
        $reportForm.removeAttr('target');
        $(this).addClass('disabled');
        var that = this;
        setTimeout(function() {
            $(that).removeClass('disabled');
        }, 12000)
    });

    //Report arrange selector
    $('input[name="fields[groupBy]"]').on('change', function (e) {
        $('input[name="fields[groupBy]"]').parents('label').removeClass('checked');
        $(this).parent().addClass('checked');

        var currentArrangement = $(this).val();
        $('button[name="generate"]').attr('data-arrangement', currentArrangement);

        $groupBy.hide();
        $groupBy.filter(`.${this.value}`).show();
    });

    //region -- country show/hide selector
    $('.countries.selected').on('click', 'label', function (e) {
        e.preventDefault();
        $('.countries.selected').toggle();
        $('.countries.selector').fadeToggle();
    });
    $('.countries.selector button.done').on('click', function (e) {
        e.preventDefault();
        $('.countries.selected').toggle();
        $('.countries.selector').fadeToggle();
        
        $('body').animate({
            scrollTop: $('.countries.selected').position().top - 140
        }, 400);        
    });
    //endregion

    //region -- country select and submit
    $('label.multilingual').on('click', function(e) {
        e.preventDefault();
    });

    $('.countries.selector input[type="checkbox"].country').on('click', function(e) {
        if (this.checked) { // add
            const $c = $(this).parents('label').clone();

            if (!$container.find(`[data-id="${this.value}"]`).length) {
                $c.find('input, .l-shadow, .l-border, .l-check, .commit, .rollback').remove();
                $c.removeClass('split');
                $c.removeAttr('for');
                $c.insertBefore('label[for="country-add"]');
            }
        } else { //remove
             $(`.selected label[data-id="${this.value}"]`).remove();
        }

        if ($(this).closest('label').hasClass('single')) {
            $(this).closest('label').find('.language input').prop( "checked", function( i, val ) {
              return !val;
            });
        }

    }).on('change', $.debounce(500, false, function () {
        $.post('', $reportForm.serialize());

        const reverse = $(this).closest('section').find('input:enabled:not(:checked)').length === 0;
        $(this)
            .closest('section')
            .find('[data-action="select-all"]')
            .html(reverse ? 'Deselect All' : 'Select All')
            .toggleClass('inverted', reverse)
    }));
    //endregion

    //region -- report locale selector
    $('.l-locales').on('click', 'button', function (e) {
        const $triggerButton = $(e.target);
        const $label = $(this).closest('label');
        const $checkboxesGroup = $label.find('input[type="checkbox"]');
        
        const $checkbox = $label.find('input[type="checkbox"].country');
        // const $checkboxCountry = $label.find('input.field-country');
        // const $checkboxLang = $label.find('input.field-language');

        const $locales = $label.find('.selection > button');
        const $commit = $label.find('.commit');
        
        $(`.selected label[data-id="${$label.data('id')}"]`).remove(); // remove

        let _state = {};

        if ($label.hasClass('checked')) {
            $label.removeClass('checked');
            $locales.removeClass('checked');
            $checkboxesGroup.prop('checked', false);
            $commit.addClass('disabled');
            $.post('', $reportForm.serialize());
        } else {
            // Commit button

            if ($triggerButton.hasClass('commit')) {
                if (!$label.hasClass('split')) {
                    _state = {};

                    $label.addClass('split');
                    $locales.removeClass('checked');
                    $checkboxesGroup.prop('checked', false);

                    $triggerButton.addClass('disabled');
                } else {
                    if (!$triggerButton.hasClass('disabled')) {

                        $locales.each(function () {
                            _state[this.dataset['locale']] = this.classList.contains('checked');
                        });

                        $label.data('_state', _state);

                        $checkbox.trigger('click');
                        $checkbox.prop('checked', true);

                        for (let k in _state) {
                            if (_state[k]) {
                                $label.find('div[data-lang=' + k + ']').find('input').prop('checked', true);
                            }
                        }

                        $label.addClass('checked');
                        $label.removeClass('split');
                        $.post('', $reportForm.serialize());
                    }
                }
            }

            // Locales button

            if ($triggerButton[0].hasAttribute('data-locale')) {
                $triggerButton.toggleClass('checked');

                if ($locales.filter('.checked').length) {
                    $commit.removeClass('disabled');
                } else {
                    $commit.addClass('disabled');
                }
            }

            // Reset button

            if ($triggerButton.hasClass('rollback')) {
                _state = {};
                $label.toggleClass('split');
                $locales.removeClass('checked');
                $checkboxesGroup.prop('checked', false);
                $commit.addClass('disabled');
                $.post('', $reportForm.serialize());
            }
        }

        e.preventDefault();
    });
    //endregion

    //region -- country tooltip
    const $tooltip = $('#tooltip');
    $('.countries label:has(:disabled)').on('mouseenter', function (e) {
        const $this = $(this), pos = $this.position();

        $tooltip.css({
            top:  pos.top  - $tooltip.outerHeight(),
            left: pos.left + 28 + ($this.outerWidth() / 2) -  ($tooltip.outerWidth() / 2),
        }).addClass('active');
    }).on('mouseleave', function () {
        $tooltip.removeClass('active');
    });
    //endregion

    //region -- exec summaries download button state
    const $download = $('input.download');
    //Countries selection widget
    $countries.on('change', function (e) {
        $download.prop('disabled', !$countries.filter(':checked').length);
    });
    //endregion

    //region - modal, spinner and nag bars
    const $modal = $('#modal');
    const $cookieBar = $('.nag.cookies');

    $cookieBar.toggleClass('active', document.cookie.replace(/(?:(?:^|.*;\s*)cookieAllowed\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== '1').find('button').on('click', function (e) {
        e.preventDefault();
        document.cookie = `cookieAllowed=1`;
        $cookieBar.removeClass('active');
    });

    $('.close', $modal).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $modal.removeClass('active');
        if ($(this).closest('#modal').find('.video')) {
           $(this).closest('#modal').find('.video').empty();
        }
    });

    $.extend($modal, {
        $content: $modal.find('article'),
        showHtml: function (html) {
            $modal.addClass('active').find('article').html(html);
        },
        showUrl: function (url) {
            $modal.find('article').load(url, () => {
                $modal.addClass('active');
            })
        },
        showTemplate: function (id, inject) {
            const [prefix, name] = id.split('-');

            const t = document.querySelector(`#${id}, #template-${prefix}`);


            $modal.addClass('active').$content.html(
                document.importNode(t.content, true) || t.content.cloneNode(true)
            );

            if (name) {
                $modal.addClass(name);
            }

            //allow some dynamic content
            if (inject instanceof Function){
                inject(t.content);
            }
        },
        showSpinner() {
            $modal.showTemplate('template-loading');
            $modal.find('.close').hide();
        }
    });


    //region -- notifications for countries
    $('input[type="checkbox"].country:disabled').parent('label').on('click', function (e) {
        e.preventDefault();
        $modal.showTemplate('modal-country-notify');

        const id = $(this).find('input:checkbox').val();
        $modal.find(`input:checkbox[value="${id}"]`).prop('checked', true);
    });
    //endregion

    $(`[href="${window.location.hash}"]`).addClass('active').siblings().on('click', function (e) {
        $(this).addClass('active').siblings().removeClass('active')
    });

    if($body.hasClass('homepage')) {
        window.onYouTubeIframeAPIReady = () => {
            $('button.play').on('click', function () {
                const $player = $(' + .player', this).addClass('active');
                const player = new YT.Player($player.find('iframe')[0], {
                    events: {
                        'onReady': () => {
                            player.playVideo();
                        },
                    }
                });

                $player.data('player', player);
            });
        };

    }
    //endregion

    //region - menus and anchor navigation effects
    $('.hamburger').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this)
            .toggleClass('is-active')
            .parents('nav')
            .toggleClass('open');
    });

    $('a[href*="#"]').on('click', function(e) {
        const $t = $(`${this.hash}, a[name=${this.hash.slice(1)}], [data-anchor="${this.hash}"]`);
        let to;

        if ($t.length) {
            if ($t.data('anchor') === '#about-the-torino-process') {
                to = $t.length ? $t.offset().top : 0;
            } else {
                if (window.innerWidth > 1025) {
                    to = $t.length ? $t.offset().top + menuOffset() : 0;
                } else {
                    to = $t.length ? $t.offset().top : 0;
                }
            }
        }

        if($t.length) {
            e.preventDefault();
            if ($(this).closest('.main').length) {
                $('nav.main .hamburger:visible').trigger('click');
            }

            $('html,body').animate(
                { scrollTop: to },
                300,
                () => {
                    //TODO: should use be history api pushstate...
                    window.history.pushState(null, this.title, window.location.href.replace(/#.+|$/, `${this.hash}`));
                }
            );
        }
    });

    $('section.indicators article').on('click', function (e) {
        $(this).toggleClass('open').find('p').fadeToggle();
    });

    //mobile breakpoint secondary nav
    $('nav.secondary').on('click', function (e) {
        if(e.target === this){
            this.classList.toggle('open');
        }
    });
    //endregion

    //region - live card search
    const $searchInput = $("#questions-search");
    if($body.hasClass('questions') && $searchInput.length){
        const $questions = $('.question');
        const $status = $searchInput.siblings('label');

        $searchInput.on("input", function (e) {
            $questions.unmark({
                done: function() {
                    $searchInput.attr('data-results', '');
                    $questions.css('opacity', 1).mark($searchInput.val(), {
                        exclude: ['i', 'label', 'input'],
                        className: "hit",
                        ignoreJoiners: true,
                        separateWordSearch: true,
                        done: (total) => {
                            $searchInput.val().trim() !== '' && $questions.not(":has(mark)").css('opacity', 0.3);
                            $status.text(total ? `${total} matches`: ``);
                        }
                    });
                }
            });
        });
    }
    //endregion

    //region - scrollmagic scenes
    const magic = new ScrollMagic.Controller({ addIndicators: false  });

    //region -- homepage effects
    if($body.hasClass('homepage')) {
        //region --- loop slider cards effect
        const
            $loop = $('.loop', $body), loop = $loop[0],
            $slice = $('.loop > article > article', $body),
            h = $loop.outerHeight();

        $slice.first().toggleClass('active');

        $slice.each(function (i) {
            const scene = new ScrollMagic.Scene({triggerElement: loop, triggerHook: 'onLeave', offset: h / 5 * i, duration: h / 5})
                .setClassToggle(this, 'active')
                .addTo(magic);
        });
        //endregion
        //region --- loop pin effect
        new ScrollMagic.Scene({ triggerElement: loop, triggerHook: 'onLeave', duration: '100%' })
            .setPin(loop)
            .on('start end', function (e) {
                $slice.first().toggleClass('start' , e.type == 'start' && e.scrollDirection === 'REVERSE');
                $slice.last().toggleClass('end' , e.type == 'end' && e.scrollDirection === 'FORWARD');
            })
            .addTo(magic);

        //endregion
        //region --- video integration for autopuse
        $('.player').each(function (i) {
            new ScrollMagic.Scene({triggerElement: this, offset: -1 * Math.abs($(this).outerHeight() / 4) , duration: '150%'})
                .on('leave', () => {
                    const player = $(this).data('player');
                    if (player && 'pauseVideo' in player) {
                        player.pauseVideo();
                    }
                })
                .addTo(magic);
        });
        //endregion

        //region --- color effects
        const $bg = $body.add('article h1');
        $('section.transition').each(function (i) {
            new ScrollMagic.Scene({ triggerElement: this, offset: $(window).height() /2,  triggerHook: 0.5})
                .on("start", function(e) {
                    const dir = [e.scrollDirection != 'REVERSE' ? 'next' : 'prev'];
                    const $out = $(e.target.triggerElement());
                    let $in = $out[dir]();

                    if ($in.hasClass('scrollmagic-pin-spacer')) {
                        $in = $in.find(' > section');
                    }

                    let c = $in.data('color') || $out.data('color');

                    $bg.css('background-color', c);
                    $bg.toggleClass('dark', c != '' && c != 'rgba(0, 0, 0, 0)' && c != '#ffffff')

                })
                .addTo(magic);
        });
        //endregion
        //region --- svg effects
        const width = navigator.appVersion.indexOf('Trident') > -1 || navigator.appVersion.indexOf('Edge') > -1 ? 1 : 10;

        $('section[data-svg-path]').each(function (i) {
            const $this = $(this);
            const units = $this.data('svg-units') || '%';

            const path = SVG(this)
                .attr({preserveAspectRatio: 'none'})
                .viewbox({x: 0, y: 0, width: 100, height: 100 })
                .size(`100${units}`, `100${units}`)
                .path($this.data('svg-path'))
                .style('stroke-width', width)
            ;

            // path.stroke({
            //     'dasharray': len,
            //     'dashoffset': len,
            // });

            // paths.push(path);
            //
            // new ScrollMagic.Scene({ triggerElement: this, duration: $this.outerHeight(), tweenChanges: true})
            //     .setTween(TweenMax.to(path.node, 5, { strokeDashoffset: 0, ease: Linear.easeNone }))
            //     .addIndicators({name: `svg-${i}`})
            //     .addTo(magic);
        });
        //endregion

        new ScrollMagic.Scene({triggerElement: '.about-section-11'})
            .setClassToggle('a[href*="/#get-involved"]', 'active') // add class toggle
            .addTo(magic);
    }
    //endregion

    //region -- main nav
    if($body.hasClass('homepage')) {    
       
        new ScrollMagic.Scene({triggerHook: 'onLeave', duration: '50%', reverse: true})
            .setClassToggle('body > nav', 'alt')
            .addTo(magic);
    }

    if($body.hasClass('resources') || $body.hasClass('hub')) {    
        new ScrollMagic.Scene({triggerElement: 'header h1', offset: -100, duration: '50%', reverse: true})
            .setClassToggle('body > nav', 'alt')
            .addTo(magic);
    }
    //endregion

    //region -- report questions page nav behaviour
    if($body.hasClass('questions')) {
        //Sticky submenu
        $('.dots [href*="#category-"]').each(function() {
            const $this = $(this.hash);
            const $next = $this.nextUntil('', 'header').first();

            new ScrollMagic.Scene({triggerElement: this.hash })
                .setClassToggle(this, "active") //add class toggle
                .duration(($next.length ? $next.offset().top : $body.outerHeight()) - $this.offset().top)
                .addTo(magic);
        });
    }

    if ($body.hasClass('report')) {
        let attempts = 5;
        let offset = menuOffset();
        const scene = new ScrollMagic.Scene({triggerHook: 'onLeave', triggerElement: 'header .secondary',  offset: offset })
            .setPin('header .secondary')
            .offset(offset)
            .addTo(magic);

        setTimeout(function sticky() {
            const check =  menuOffset();
            if (offset !== check) {
                scene.offset(check);
                scene.update();
                offset = check;
            }
            if (attempts--) {
                setTimeout(sticky, 500);
            }
        },500);
    }
    //endregion

    //region -- questions list sticky bottom (teehee)
    if ($body.hasClass('questions')) {
        const $sticky = $('nav.bottom');
        let last;
        new ScrollMagic.Scene({triggerHook: 'onLeave', triggerElement: 'body'})
            .on('update', (e) => {
                $sticky.toggleClass('hide', e.scrollPos ===0 || e.scrollPos > last);
                last = e.scrollPos;
            })
            .addTo(magic);
    }
    //endregion

    //endregion

    //region - form textarea effects
    $('form.sprout textarea').on('focus keyup', function () {
        const $this = $(this), $counter = $this.siblings('.counter').first();
        $this.attr('rows', this.value.split('\n').length + 1);
        $counter.html(parseInt($this.attr('maxlength')) - this.value.length);
    }).on('blur', function () {
        $(this).attr('rows', this.value == '' ? 1 : this.value.split('\n').length + 1 );
    });
    //endregion

    //region - circle scale direction
    let units;
    window.addEventListener('resize', (function resize() {
        const reset = window.innerHeight > window.innerWidth ? 'w' : 'h';

        if (units !== reset) {
            $('.loop svg').attr({
                width: `100v${reset}`,
                height: `100v${reset}`
            }).css({
                'max-width': `100v${reset}`,
                'max-height': `100v${reset}`
            });
            units = reset;
        }
        return resize;
    })());

    //endregion

    //region - stream live/watch pages
    if ($body.hasClass('stream')) {
        const $streams = $('.player').hide();
        let lang = (window.location.hash || '#en').slice(1);

        //Care - api only works after this callback.
        window.onYouTubeIframeAPIReady = () => {
            $streams.each(function () {
                $(this).data('player', new YT.Player($(this).find('iframe')[0]));
            });

            //activate selected
            $streams.filter(`[data-language="${lang}"]`).show();
            $(`a[href="#${lang}"].inverted`).addClass('active');

            //handle buttons
            $('a[href^="#"].inverted').on('click', function (e) {
                const $this = $(this), _lang = (this.href.slice(-2) || 'en');

                console.log(`switching from ${lang} to ${_lang} - hash ${this.href.slice(-2)}`);

                e.preventDefault();

                if (lang === _lang) {
                    //repeated clicks are no-op
                    return;
                }

                $streams
                //Stop all streams
                    .each(function () {
                        const player = $(this).data('player');
                        if (player && 'pauseVideo' in player) {
                            player.pauseVideo();
                        }
                    })
                    .hide()
                    //Find one we want...
                    .filter(`[data-language="${_lang}"]`)
                    .show();

                //Indicate which stream is active
                $this.addClass('active').siblings().removeClass('active');

                lang = _lang;
            });
        }
    }
    //endregion

    //region - only add youtube api to some pages
    if($body.hasClass('homepage') || $body.hasClass('stream')){
        const tag = document.createElement('script');
        tag.id = 'popup-player';
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    //endregion

    //region - resources hub
        const container = $('#resources');
        const loader = $('#loader');

        $('.dropdown').on('click', 'button', function (e) {
            e.preventDefault();
            $(e.delegateTarget).toggleClass('open');
        });

        

            $('#resources').delegate('.card-thumb, .card-body div, .action .button', 'click', function(e) {
                
                

                e.preventDefault();

                const $this = $(this);
                const $theCard = $(this).closest('.card');
                let $theLink;

                $('html, body').animate({scrollTop: $theCard.offset().top - 200}, 500);

                if ($this.closest('.card').hasClass('languages') && $this.is('.card-thumb, .card-body div')) {
                    return false;
                }

                if ($this.is('a')) {
                    $theLink = $this;
                } else {
                    $theLink = $this.closest('.card').find('.action a');
                }
                const $parent = $this.closest('.card').find('.action');

                if ($this.closest('.card').hasClass('video')) {

                    const video = $this.closest('.card').find('.card-thumb .video').clone();
                    $modal.showHtml(video);
                } else {
                    if (msieCheck()) {
                        window.location = $theLink.attr('href');
                    } else {
                        let req = new XMLHttpRequest();
                        req.open('GET', $theLink.attr('href'), true);
                        req.responseType = 'blob'; 
                        console.log(1125);
                        req.onload = function (event) {
                            let blob = req.response;
                            console.log(blob.size);
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = $theLink.data('name');
                            document.body.appendChild(link);
                            link.click();
                            $parent.addClass('done');
                            setTimeout(function () {
                                $parent.removeClass('done');
                            }, 2000);                    
                        };
                        req.send();                           
                    }
               

                    // $.ajax({
                    //     url: $theLink.attr('href'),
                    //     success: download.bind(true, $theLink.data('mimetype'), $theLink.data('name'))
                    // }).done(function () {
                    //     $parent.addClass('done');

                    //     setTimeout(function () {
                    //         $parent.removeClass('done');
                    //     }, 2000);
                    // });
                }
            });


        $('#showMore').on('click', function (e) {
            e.preventDefault();

            const self = $(this);
            const request = self.data('get');
            const page = self.data('page');
            const batch = self.data('batch');
            const offset = page * batch;
            const newPage = page + 1;

            loader.show();
            self.hide();

            $.get(request, {
                offset: offset,
                limit: batch
            }, function (data) {
                container.append(data);

                if ($('#data-wrapper').data('sortby') === 'category') {
                    inserCards();
                }

                loader.hide();

                self.data('page', newPage);

                if (self.data('page') === self.data('total')) {
                    self.hide();
                } else {
                    self.show();
                }
            });
        });

        function categorySwitcher() {
            let dataWrapper = $('#data-wrapper');
            let dropdownButton = $('.sorting .dropdown');
            let categoryButton = $('.sorting .helper > span');

            dropdownButton.find('a').on('click', function(e) {
                dropdownButton.removeClass('open');
                e.preventDefault();

                if ($(this).attr('href') === 'category') {
                    dataWrapper.attr('data-sortby', 'category');
                    dropdownButton.find('button').text('Category');
                    $('div.categories').addClass('on');

                    inserCards();

                } else {
                    document.location.href = '?order=' + e.target.getAttribute('href');
                }
            });

            categoryButton.delegate('.close', 'click', function(e) {
                let currentCategory = $(this).closest(categoryButton).attr('data-category');
                let checkedCategories = [];

                if ($(this).closest(categoryButton).hasClass('disabled')) {
                    $(this).closest(categoryButton).removeClass('disabled');
                } else {
                    $(this).closest(categoryButton).addClass('disabled');
                    dataWrapper.removeAttr('categories-', currentCategory);
                }
                categoryButton.each(function() {
                    if (!$(this).hasClass('disabled')) {
                        checkedCategories.push($(this).attr('data-category'));
                    }
                });
                dataWrapper.attr('data-categories', checkedCategories);
            });
        }

        function inserCards () {
            $('div[data-category]').each(function () {
                const $this = $(this);
                const category = $this.data('category');

                $(`div#${category}`).find('.row').append($this);
            });
        }

        categorySwitcher();
    //endregion
});

//region - polyfills and helpers
//region -- scrollElement for IE11
document.scrollingElement = document.scrollingElement ? document.scrollingElement : (function () {
    const d = document;
    return (
        d.documentElement.scrollHeight > d.body.scrollHeight &&
        d.compatMode.indexOf('CSS1') == 0 ?
        d.documentElement :
        d.body
    );
}());
//endregion
//region -- template for IE11
(function templatePolyfill(d) {
    if('content' in d.createElement('template')) {
        return false;
    }

    const qPlates = d.getElementsByTagName('template'),
        plateLen = qPlates.length;
    let elPlate,
        qContent,
        contentLen,
        docContent;

    for(let x=0; x < plateLen; ++x) {
        elPlate = qPlates[x];
        qContent = elPlate.childNodes;
        contentLen = qContent.length;
        docContent = d.createDocumentFragment();

        while(qContent[0]) {
            docContent.appendChild(qContent[0]);
        }

        elPlate.content = docContent;
    }
})(document);
//endregion
//endregion


function msieCheck() {
    let ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    let isMsie = false;

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        isMsie = true;
    }
    return isMsie;
}